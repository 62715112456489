.success-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.success-page h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 105%;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.success-page p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 105%;
}

/* .success-page button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: auto;
    height: 48px;
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;    
    letter-spacing: -0.01em;
    border: 1px solid #F17CAF;
    background: #FCFCFC;
    color: #F17CAF;
} */

.success-page #confir {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}

.zigzag {
  position: relative;
}
.zigzag::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18px;
  background: linear-gradient(-135deg, #fff8f8 12px, transparent 0%),
    linear-gradient(135deg, #fff8f8 12px, transparent 0%);
  background-size: 8px;
}
.zigzag::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  background: linear-gradient(-45deg, #fff8f8 12px, transparent 0%),
    linear-gradient(45deg, #fff8f8 12px, transparent 0%);
  background-size: 8px;
}
