@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff8f8 !important;
}

input[type="checkbox"]:checked {
  accent-color: #e7075b;
}

.accordion-button {
  color: #ffffff !important ;
  height: 90px;
}

.accordion-flush .accordion-item {
  color: #ffffff !important;
  border-radius: 10px !important;
}

.accordion-button::after {
  color: #fff8f8 !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff !important;
  background-color: transparent !important;
}
.accordion-body {
  height: auto;
}
.accordion-button:focus {
  color: #ffffff;
  box-shadow: none;
}

.payment-div form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow:
    0px 0px 0px 0.5px rgba(23, 23, 24, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.payment-div #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-div #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.payment-div button {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  border-radius: 12px;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.payment-div button:hover {
  filter: contrast(115%);
}

.payment-div button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.payment-div .spinner,
.payment-div .spinner:before,
.payment-div .spinner:after {
  border-radius: 50%;
}

.payment-div .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.payment-div .spinner:before,
.payment-div .spinner:after {
  position: absolute;
  content: "";
}

.payment-div .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #e7075b;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.payment-div .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.divider {
  margin: 0 !important;
  color: inherit;
  border: none !important;
  border-top: 1px solid;
  opacity: 0.25;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  height: 2px;
  margin-top: 75px !important;
}

#title-part {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: #000000;
}

#subtitle-part {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 22px;
}

#title-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  color: #303a42;
  text-align: center;
}

.form_item {
  position: relative;
  margin-bottom: 18px;
}

.form_item input,
.form_item select,
.form_item textarea {
  width: 100%;
  outline: none;
  display: block;
  color: #6e7684;
  font-size: 16px;
  padding: 0px 20px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid #d8d8d8;
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
.form_item input:focus,
.form_item textarea:focus {
  border: none;
  border-bottom: 1px solid rgba(241, 124, 175, 1);
}
.form_item input {
  height: 55px;
}
.form_item textarea {
  min-height: 150px;
  padding: 15px 20px;
}
.form_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 33px;
  color: #303a42;
}
.custom_btn {
  height: 60px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  border: none !important;
  padding: 0px 30px;
  line-height: 40px;
  margin-top: 30px;
  text-align: center;
  display: inline-block;
  font-family: "Poppins";
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  border-radius: 25px;
}

.title-adress {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #fff8f8;
}

.content-adress {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff8f8;
}

.linear-gradient {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}

.react-pdf__Page__textContent.textLayer {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

#bodyOfApp {
  scroll-margin-top: 30px; /* whatever is a nice number that gets you past the header */
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .payment-div form {
    width: 80vw;
    min-width: initial;
  }
}
