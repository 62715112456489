.jobs-item {
  border: 1px solid #dec4ce;
}

.job-button {
  font-family: "Popins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #ffffff;
  height: 48px;
  width: 140px;
  margin: auto 10px;
  /* padding: 10px 20px; */
  background-color: #f17caf !important;
  /* background: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%); */
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 6px !important;
  border: none;
}

.pro-button {
  font-family: "Popins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #ffffff;
  height: 48px;
  width: 140px;
  margin: auto 10px;
  /* padding: 10px 20px; */
  background-color: #e7075b !important;
  /* background: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%); */
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 6px !important;
  border: none;
}

.job-button-postuler {
  font-family: "Popins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #ffffff;
  height: 48px;
  width: 220px;
  /* padding: 10px 20px; */
  background: linear-gradient(
    89.87deg,
    #f17caf 2.08%,
    #e7075b 98.27%
  ) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 6px !important;
  border: none;
}

.job-button:hover {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}

.title-job {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.text-job {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.job-details-header {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}

.job-details-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;

  color: #ffffff;
}

.text-justify {
  text-align: justify;
}

#bread {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
