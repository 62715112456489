.carousel-caption2 h3 {
  font-family: "Poppins";
  font-style: normal;
  text-align: center;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  text-shadow: 0px 5px 24px rgba(0, 0, 0, 0.35);
}

.carousel-caption2 p {
  font-family: "Poppins";
  text-align: center;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 5px 24px rgba(0, 0, 0, 0.35);
}

.carousel-caption2 small {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  color: #fff8f8;
}
