.logo {
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.logo2 {
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

nav {
  margin: 13px 0;
}

nav ul {
  font-weight: 600;
  font-size: 14px;
  list-style: none;
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  text-transform: uppercase;
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}

.social-icon {
  gap: 20px !important;
  color: #9a9fa5;
}

.shopingCard {
  font-size: 30px;
  color: #64748b;
}
.humberger {
  display: none;
}

.icon-filter {
  filter: grayscale(1);
}

.icon-filter:hover {
  filter: none;
}

@media (max-width: 1400px) {
  .container {
    position: relative;
  }

  .humberger {
    display: block;
  }

  .navbar {
    display: none;
  }

  .social {
    display: none;
    margin-top: 10px;
  }

  .showMenu {
    transition: all 0.5s ease;
    display: flex;
    z-index: 100;
    background: #fff;
    color: white;
    position: absolute;
    width: 323.63px;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    height: auto;
    padding: 10px 20px;
  }
  .navLink {
    color: black;
    z-index: 200;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .navLink li {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .navLink li {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .humberger {
    border: none;
    background: none;
    width: auto;
  }
  .humberger svg {
    color: #000000;
    font-size: 40px;
  }
}
