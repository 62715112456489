.event_group_title > h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 105%;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* .event_card {
    margin-bottom: 40px;
} */

#divider_events {
  margin: 0 !important;
  color: inherit;
  /* border: none !important;
    border-top: 1px solid; */
  opacity: 0.25;
  height: 2px;
  margin-top: 0px !important;
}

.event_group_title {
  gap: 15px;
}
.event_group_title svg {
  /* padding: 5px; */
  width: 27px;
  height: 27px;
  fill: #f17caf;
  font-size: 40px;
  border: 0.5px solid #e7075b;
  border-radius: 4px;
}

/* .event_group_content {
    padding: 40px 0px;
    gap: 95px;
    flex-wrap: wrap;
} */
