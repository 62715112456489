.tarif-div {
  width: 100%;
}

.tarif-section {
  position: relative;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.tarif-section form {
  padding: 5px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #ddddeb;
  border-radius: 10px;
}

.stock-pink-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  border-radius: 10px;
  gap: 10px;
}

.stock-blue-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background: linear-gradient(210.09deg, #3747a6 -8.72%, #189ed8 128.29%);
  border-radius: 10px;
  gap: 10px;
}

.locker-details {
  width: 100%;
  background: #fff8f8;
  border: 1px solid #ddddeb;
  border-radius: 10px;
}

.locker-type-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}

.text-white {
  font-weight: 100;
}

.tf {
  text-align: center;
  font-size: 12px;
}
.tarif-div {
  align-items: flex-end;
}

.tarif-div p {
  font-family: "Poppins";
  font-style: normal;
  /* or 121% */
  text-align: center;
}

.locker-type-p {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
  border: 1px solid #ffffff;
}

.form-check-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* line-height: 24px; */
  color: #47393e;
}

.checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddddeb;
  border-radius: 4px;
}
.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #e7075b;
  border-color: #e7075b;
  border-radius: 6px;
}

.tcardre {
  z-index: -1;
  position: absolute;
  width: 82%;
  height: 420px;
  border: 1px solid #ddddeb;
  border-radius: 10px;
}

.tarif-detail {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-end;
  gap: 25px;
  padding: 10px 0px;
}

.action_buton {
  gap: 20px;
}

.action_buton button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 186px;
  height: 48px;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  background: #fcfcfc;
  border: 1px solid #f17caf;
  color: #f17caf;
}

.action_buton button:hover {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}

.label-muted {
  color: #bbb;
}
