.button-choos {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px 12px;
    gap: 8px; */
  width: 100px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  background: #fcfcfc;
  border: 1px solid #f17caf;
  color: #f17caf;
}

.button-modif {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px 12px;
    gap: 8px; */
  width: 100px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  color: #fff;
  letter-spacing: 0.05em;
}

/* .button-active-locker{
    background: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%);    
    color: #FCFCFC;
} */

.button-choos:hover {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}

.tarif-container h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #f17caf;
}

.nouveau-badge {
  background: #66b7ff;
  /* shadow/sm */
  border-radius: 5px;
  font-family: "Poppins";
  text-align: center;
  color: #ffffff;
}

.muted-badge {
  background: #959292;
  border-radius: 5px;
  font-family: "Poppins";
  text-align: center;
  color: #ffffff;
}

.locker_stock {
  background: #119a36;
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-family: "Poppins";
  /* identical to box height, or 150% */
  text-align: center;
  color: #fff8f8;
}

.empty-badge {
  margin-top: 15px;
  padding: 10px 0;
}

.locker-choosen {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */
  color: #ffffff;
  width: 175px;
  height: 38px;
  left: 913px;
  top: 284px;
  /* Linear */
  padding-top: 10px;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}
