.event-img-div {
  width: 210px;
  /* height: 389.11px; */
}

.event-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  color: #000000;
  padding: 15px 5px;
}

.form-step {
  gap: 10px;
}

.date-pick-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

section {
  width: 100%;
}

/* .date-pick-div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
} */

/* .active-day-picker{
    border-width: 0.5px !important;
    border-style: solid !important;
    border-image: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%) !important;
    border-image-slice: 1 !important;  
    border-radius: 10px !important;
} */

.date-pick-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #FFFFFF; */
  /* border: 1px solid #DDDDEB; */
  border-radius: 10px;
  /* width: 100%; */
}

/* .date-pick-section svg {
    font-size: 10px;
    color: #9A9FA5;
} */

.date-pick-section button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 130px;
  height: 38px;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  margin: 0px 10px 0px 10px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #f17caf;
}

.date-pick-section button.non-selected {
  background: #fcfcfc;
  color: #f17caf;
}

.date-pick-section button.non-selected:hover {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}

.date-pick-section button.selected {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}

.action_buton {
  width: 100%;
  margin-top: 70px !important;
  justify-content: center;
}

.fieldset {
  padding: 40px 10px 10px 10px;
  border: 0.5px solid;
  border-radius: 5px;
}

.fieldset2 {
  padding: 40px 10px 10px 10px;
  border: 0.5px solid #f17caf;
  border-radius: 5px;
}

.title-forfait {
  font-family: "Poppins";
  font-weight: 700 !important;
}
