.stepper-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  border-top: 1px solid #dec4ce;
  border-bottom: 1px solid #dec4ce;
  padding-top: 20px;
  padding-bottom: 20px;
}

.step-div {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 0.5rem;
}
.step-div p {
  margin-bottom: 1rem;
}

.step-div a {
  text-decoration: none;
  color: black;
}

.step-div a:hover {
  text-decoration: none;
  color: #f17caf;
}

.elipse-div {
  position: absolute;
  bottom: -10px;
  margin-left: 15px;
}

.stepper-icon-box {
  cursor: pointer;
}
