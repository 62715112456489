.footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

/*.f-link{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}*/

footer {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  /* height: 161px; */
  flex-direction: column;
}

footer a:hover .icon-filter {
  filter: none;
}
