.search {
  width: 100%;
  margin: 33px 0;
}

.search form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-check-inline {
  margin: 10px 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
