.title h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
