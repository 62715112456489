.event_card button {
  font-family: "Popins", sans-serif;
  /* font-style: normal;
    font-size: 17px !important; */
  /* font-weight: 700;
    color: #ffffff; */
  padding: 12px 20px;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  backdrop-filter: blur(4px);
  border-radius: 12px !important;
  border: none;

  /* font-family: 'Clash Display'; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}

.event_card {
  display: flex;
  flex-direction: column;
  @apply md:tw-max-w-[20%] md:tw-mx-[2.5%] tw-my-7 sm:tw-w-[45%] sm:tw-mx-[2.5%] tw-w-[60%];
}

/* .event_card {
    display: flex;
    flex-direction: column;
    @apply md:tw-max-w-[20%] md:tw-min-w-[18%] md:tw-mx-[2%] sm:tw-min-w-[30%] sm:tw-max-w-[45%] sm:tw-mx-[2.5%] tw-my-7;
    /* align-items: center;
    justify-content: center; */
/* width: 250px; */
/* height: 558px; */

/* #event-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: linear-gradient(89.87deg, #F17CAF 2.08%, #E7075B 98.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
} */

.event_card h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.event_description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #605d5d;
  padding: 5px 1px 8px 1px;
  width: 100%;
}

.event-image span {
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}
