.react-tel-input .form-control {
  border: none !important;
  width: 100% !important;
}

.ident-div {
}

.modal input {
  font-size: 20px;
  text-align: center;
}

input:focus {
  outline: none;
  border: 1px solid #e7075b;
}

#exLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #9a9fa5;
}

.ident-div button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 30px;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.btn-verify {
  display: flex;
  justify-content: space-evenly;
  background: #ffffff;
}

.btn-verify svg {
  font-size: 25px;
  color: rgb(93, 242, 93);
}

.contactFriend {
  border: 1px solid #ddddeb;
  border-radius: 4px;
}

.contactFriend-p {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9a9fa5;
}

/* .action_buton {
    margin-top: 100px;
    justify-content: flex-center !important;
} */

.action_buton button:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 186px;
  height: 48px;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  background: #e7075b;
  border: 1px solid #f17caf;
  color: #fcfcfc;
}

.action_buton button:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 186px;
  height: 48px;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  background: #fcfcfc;
  border: 1px solid #6b7280;
  color: #6b7280;
}

.action_buton button:nth-child(2):hover {
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  color: #fcfcfc;
}
